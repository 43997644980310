import firebase from 'firebase'

// Initialize Firebase
let config = {
    apiKey: "AIzaSyDna4HhZUwEouSmpEYY_98IS1XHzl7Eob8",
    authDomain: "atandocabos.firebaseapp.com",
    databaseURL: "https://atandocabos.firebaseio.com",
    projectId: "atandocabos",
    storageBucket: "atandocabos.appspot.com",
    messagingSenderId: "725083307020",
    appId: "1:725083307020:web:be6d146cfe88d1c01c8229"
};

firebase.initializeApp(config);
