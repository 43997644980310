import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Trace from "./views/Trace";
import Flow from "./views/Flow";

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/',
      name: 'trace',
      component: Trace
    },
    {
      path: '/flow',
      name: 'flow',
      component: Flow
    }
  ]
})
