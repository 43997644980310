<template>
    <v-container class="fill-height scale SignIn" align="center" justify="center">
        <v-form v-model="signin.form" style="width: 100%">
           <v-col>
               <v-row class="text-md-center my-5" align-content="center" justify="center">
                   <h1 class="white--text">Bienvenido</h1>
               </v-row>
               <v-row>
                   <v-text-field
                           dark
                           outlined
                           rounded
                           v-model="signin.email"
                           label="Email"
                           required
                   ></v-text-field>
               </v-row>
               <v-row>
                   <v-text-field
                           dark
                           outlined
                           rounded
                           v-model="signin.password"
                           label="Passowrd"
                           required
                           type="password"
                   ></v-text-field>
               </v-row>
               <v-row>
                   <v-btn
                           block
                           solo
                           rounded
                           large
                   >Ingresar</v-btn>
               </v-row>
               <v-row style="height: 50px">
                   <v-divider></v-divider>
               </v-row>
               <v-row>
                   <v-btn
                           class="my-2"
                           block
                           solo
                           rounded
                           large
                           color="blue lighten-4"
                   >Ingresar con Facebook</v-btn>
               </v-row>
               <v-row>
                   <v-btn
                           class="my-2"
                           block
                           solo
                           rounded
                           large
                           color="red lighten-4"
                   >Ingresar con Google</v-btn>
               </v-row>
           </v-col>
        </v-form>
    </v-container>
</template>

<script>
    import './_SignIn.scss'
    export default {
        name: 'SignIn',
        props: {
        },
        data () {
            return {
                signin: {
                    form: false,
                    email: '',
                    password: ''
                }
            }
        }
    }
</script>
