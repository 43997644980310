<template>
    <v-container class="fill-height scale">
        <v-col class="fill-height flex-nowrap">
            <v-row align="center" justify="center" class="display-1 white--text">
                {{ flow.title}}
            </v-row>
            <!-- Mobile -->
            <v-col align="center" justify="center" class="fill-height flex-nowrap hidden-lg-and-up" style="height: 95%" >
                <v-row align="center" class="" justify="center">
                    <v-col :key="index" align="center" justify="center" v-for="(item, index) in flow.line[0]">
                        <v-chip class="text-md-center font-weight-black" color="#9ac234" dark
                                style="height: 40px; font-size: 12px !important;">
                            {{ item }}
                        </v-chip>
                    </v-col>
                </v-row>
                <div :key="index" v-for="(item, index) in flow.line" v-if="index != 0 && index < flow.line.length - 1">
                    <v-row align="center" class="flex-nowrap" justify="center" style="height: 100px">
                        <v-col align="center" class="pa-0" justify="center">
                            <v-icon dark size="64">arrow_drop_down</v-icon>
                        </v-col>
                    </v-row>
                    <v-row align="center" class="flex-nowrap" justify="center">
                        <v-col align="left" class="pa-0" justify="center" style="width: 100px">
                            <v-img :src="item" class="rotate-90" contain width="100px"/>
                        </v-col>
                        <v-col align="left" class="flex-nowrap" justify="center" style="width: 90%">
                            <Files :files="file.files" :key="file.title" :title="file.title"
                                   v-for="(file) in flow.data[index-1]"/>
                        </v-col>
                    </v-row>
                </div>
                <v-row align="center" justify="center" class="flex-nowrap" style="height: 100px">
                    <v-col align="center" justify="center" class="pa-0">
                        <v-icon size="64" dark>arrow_drop_down</v-icon>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center" class="flex-nowrap">
                    <v-col align="center" class="pa-0" justify="center">
                        <v-img :src="flow.line[flow.line.length-1]" contain width="60%"/>
                    </v-col>
                </v-row>
            </v-col>
            <!-- Desktop -->
            <v-row align="center" justify="center" class="fill-height flex-nowrap hidden-md-and-down" style="height: 95%" >
                <v-col align="center" class="fill-height" justify="center">
                    <v-row align="center" class="fill-height" justify="center">
                        <v-chip :key="index" class="text-md-center font-weight-black pa-5" color="#9ac234" dark
                                style="height: 80px; font-size: 12px !important;" v-for="(item, index) in flow.line[0]">
                            {{ item }}
                        </v-chip>
                    </v-row>
                </v-col>
                <v-layout :key="index" class="fill-height" v-for="(item, index) in flow.line"
                          v-if="index != 0 && index < flow.line.length - 1">
                    <v-col class="fill-height">
                        <v-row align="center" class="flex-nowrap" justify="center" style="height: 40%">
                        </v-row>
                        <v-row align="center" justify="center" style="height: 100px">
                            <v-icon dark size="48">play_arrow</v-icon>
                        </v-row>
                        <v-row align="center" class="flex-nowrap" justify="center" style="height: 40%">
                        </v-row>
                    </v-col>
                    <v-col class="fill-height">
                        <v-row align="center" class="flex-nowrap" justify="center" style="height: 40%">
                            <Files :files="file.files" :key="file.title" :title="file.title"
                                   v-for="(file, id) in flow.data[index-1]" v-if="id == 1"/>
                        </v-row>
                        <v-row align="center" justify="center" style="height: 100px">
                            <v-img :src="item" contain width="150px"/>
                        </v-row>
                        <v-row align="center" class="flex-nowrap" justify="center" style="height: 40%">
                            <Files :files="file.files" :key="file.title" :title="file.title"
                                   v-for="(file, id) in flow.data[index-1]" v-if="id == 0"/>
                        </v-row>
                    </v-col>
                </v-layout>
                <v-col class="fill-height">
                    <v-row align="center" justify="center" style="height: 40%" class="flex-nowrap">
                    </v-row>
                    <v-row align="center" justify="center" style="height: 100px">
                        <v-icon size="48" dark>play_arrow</v-icon>
                    </v-row>
                    <v-row align="center" justify="center" style="height: 40%" class="flex-nowrap">
                    </v-row>
                </v-col>
                <v-col class="fill-height">
                    <v-row align="center" justify="center" style="height: 40%" class="flex-nowrap">
                    </v-row>
                    <v-row align="center" justify="center" style="height: 100px">
                        <v-img :src="flow.line[flow.line.length-1]" contain width="150px"/>
                    </v-row>
                    <v-row align="center" justify="center" style="height: 40%" class="flex-nowrap">
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
    import Files from "../components/Files/Files";

    export default {
  components: {
      Files
  },
    data () {
      return {
          flow: {
              title: 'Trazabilidad Venta Rehrig Pacific',
              line: [
                  ['Reciclajes Marino Aysen', 'AC Redes Curaco de Vélez'],
                  require('@/assets/recollect.svg'),
                  require('@/assets/bbcc.svg'),
                  require('@/assets/comberplast.svg'),
                  require('@/assets/rehrig.svg')
              ],
              data: [
                  [
                      {
                          title: '11.650 KG Aysen',
                          files: [
                              {
                                  icon: 'factura.svg',
                                  title: 'Factura',
                                  subtitle: 'N° 103',
                                  disabled: false,
                                  link: 'https://find.bloqs4.com/QmdBvW5i7pT4X1hrzFZGx8aBrXJMjgH9CK4MrPrRw5hdK9'
                              },
                              {
                                  icon: 'guia.svg',
                                  title: 'Guía de Despacho',
                                  subtitle: 'N° 148',
                                  disabled: false,
                                  link: 'https://find.bloqs4.com/QmdapYpqsqK9sZsFJvxwA91Bhz4gaaxZm26UuRiZYqE4fu'
                              },
                              {
                                  icon: 'ticket.svg',
                                  title: 'Ticket de Pesaje',
                                  subtitle: 'N° 14.841',
                                  disabled: false,
                                  link: 'https://find.bloqs4.com/QmPPML2NsmSPwaXQH723B8iAjvJ7dz3vbMvyMh8ZYazrsc'
                              },
                              {
                                  icon: 'ubicacion.svg',
                                  title: 'Ubicación',
                                  subtitle: '',
                                  disabled: false,
                                  link: 'https://goo.gl/maps/yasY5CNcJfcrYJi48'
                              }
                          ]
                      },
                      {
                          title: '11.950 KG Curaco de Vélez',
                          files: [
                              {
                                  icon: 'factura.svg',
                                  title: 'Factura',
                                  subtitle: 'N° 114',
                                  disabled: false,
                                  link: 'https://find.bloqs4.com/QmZyQbK3RR8HAvEzpas9FBscAPUjRoNpp5PWXfSobjDjfJ'
                              },
                              {
                                  icon: 'guia.svg',
                                  title: 'Guía de Despacho',
                                  subtitle: 'N° 164',
                                  disabled: false,
                                  link: 'https://find.bloqs4.com/QmZE6B1nDbQvj7RQkvnM9QvajjvPo6nDgQxF4BZuu59pGD'
                              },
                              {
                                  icon: 'ticket.svg',
                                  title: 'Ticket de Pesaje',
                                  subtitle: 'N° 7865-7873',
                                  disabled: false,
                                  link: 'https://find.bloqs4.com/QmYL6bjoZYAPscxBXhLY17gcY59TrhDMZrbLj4xMMLG3jh'
                              },
                              {
                                  icon: 'ubicacion.svg',
                                  title: 'Ubicación',
                                  subtitle: '',
                                  disabled: false,
                                  link: 'https://goo.gl/maps/pgfMFKVvALXZY5vNA'
                              }
                          ]
                      }
                  ],
                  [
                      {
                          title: '23.920 KG Mix',
                          files: [
                              {
                                  icon: 'factura.svg',
                                  title: 'Factura',
                                  subtitle: 'N° 587',
                                  disabled: false,
                                  link: 'https://find.bloqs4.com/QmUPksqCdWjydn2UC6VRgbDYeyfbAFNZU6Tmjjqz8tHeRm'
                              },
                              {
                                  icon: 'guia.svg',
                                  title: 'Guía de Despacho',
                                  subtitle: 'N° ',
                                  disabled: true,
                                  link: ''
                              },
                              {
                                  icon: 'ticket.svg',
                                  title: 'Ticket de Pesaje',
                                  subtitle: 'N° ',
                                  disabled: true,
                                  link: ''
                              },
                              {
                                  icon: 'ubicacion.svg',
                                  title: 'Ubicación',
                                  subtitle: '',
                                  disabled: true,
                                  link: ''
                              }
                          ]
                      }
                  ],
                  [
                      {
                          title: '20.000 KG Mix',
                          files: [
                              {
                                  icon: 'factura.svg',
                                  title: 'Factura',
                                  subtitle: 'N° 43.357',
                                  disabled: false,
                                  link: 'https://find.bloqs4.com/Qmebm6hQX6PTKwKc7DfD9XV3fB6qoGcotmcXggs1WLtpYM'
                              },
                              {
                                  icon: 'guia.svg',
                                  title: 'Guía de Despacho',
                                  subtitle: 'N° ',
                                  disabled: true,
                                  link: ''
                              },
                              {
                                  icon: 'ticket.svg',
                                  title: 'Ticket de Pesaje',
                                  subtitle: 'N° ',
                                  disabled: true,
                                  link: ''
                              },
                              {
                                  icon: 'ubicacion.svg',
                                  title: 'Ubicación',
                                  subtitle: '',
                                  disabled: false,
                                  link: 'https://goo.gl/maps/Lh1JK4P2NN22Y86X6'
                              }
                          ]
                      }
                  ]
              ]
          },
      }
    }
};
</script>

<style>
    .rotate-90 {
        transform: rotate(90deg);
    }

    .rotate-45 {
        transform: rotate(45deg);
    }

    .col {
        padding: auto;
    }
</style>