<template>
  <v-app class="app">
    <v-img contain :src="require('@/assets/atando_cabos.svg')" class="logo" />
    <v-content class="pa-0 ma-0">
      <router-view />
    </v-content>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data: () => ({
    //
  }),
};
</script>

<style>
  .app {
    background: rgba(30,53,118,1) !important;
    background: -moz-linear-gradient(left, rgba(30,53,118,1) 0%, rgba(85,150,164,1) 100%) !important;
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(30,53,118,1)), color-stop(100%, rgba(85,150,164,1))) !important;
    background: -webkit-linear-gradient(left, rgba(30,53,118,1) 0%, rgba(85,150,164,1) 100%) !important;
    background: -o-linear-gradient(left, rgba(30,53,118,1) 0%, rgba(85,150,164,1) 100%) !important;
    background: -ms-linear-gradient(left, rgba(30,53,118,1) 0%, rgba(85,150,164,1) 100%) !important;
    background: linear-gradient(to right, rgba(30,53,118,1) 0%, rgba(85,150,164,1) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e3576', endColorstr='#5596a4', GradientType=1 ) !important;
  }
  .logo {
    height: auto;
    max-height: 550px;
    width: 100%;
    position: absolute !important;
    top: 150px;
  }

  .logo::after {
    transform: translateY(40%);
  }

</style>
