<template>
    <v-container class="fill-height " align="center" justify="center">
        <v-col class="fill-height flex-nowrap">
            <v-row align="center" justify="center" class="display-1 white--text">
                {{ flow.title }}
            </v-row>
            <v-row>
                <!-- Input -->
                <v-chip>Default</v-chip>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import Files from "../components/Files/Files";

export default {
  components: {
      Files
  },
    data () {
      return {
          flow: {
              title: 'Trazabilidad Venta Rehrig Pacific',
              start: [],
              end: [],
              data: [
                  {
                      title: '11.650 KG Aysen',
                      files: [
                          {
                              icon: 'factura.svg',
                              title: 'Factura',
                              subtitle: 'N° 103',
                              disabled: false,
                              link: 'https://find.bloqs4.com/QmdBvW5i7pT4X1hrzFZGx8aBrXJMjgH9CK4MrPrRw5hdK9'
                          },
                          {
                              icon: 'guia.svg',
                              title: 'Guía de Despacho',
                              subtitle: 'N° 148',
                              disabled: false,
                              link: 'https://find.bloqs4.com/QmdapYpqsqK9sZsFJvxwA91Bhz4gaaxZm26UuRiZYqE4fu'
                          },
                          {
                              icon: 'ticket.svg',
                              title: 'Ticket de Pesaje',
                              subtitle: 'N° 14.841',
                              disabled: false,
                              link: 'https://find.bloqs4.com/QmPPML2NsmSPwaXQH723B8iAjvJ7dz3vbMvyMh8ZYazrsc'
                          },
                          {
                              icon: 'ubicacion.svg',
                              title: 'Ubicación',
                              subtitle: '',
                              disabled: false,
                              link: 'https://goo.gl/maps/yasY5CNcJfcrYJi48'
                          }
                      ]
                  },
                  {
                      title: '11.950 KG Curaco de Vélez',
                      files: [
                          {
                              icon: 'factura.svg',
                              title: 'Factura',
                              subtitle: 'N° 114',
                              disabled: false,
                              link: 'https://find.bloqs4.com/QmZyQbK3RR8HAvEzpas9FBscAPUjRoNpp5PWXfSobjDjfJ'
                          },
                          {
                              icon: 'guia.svg',
                              title: 'Guía de Despacho',
                              subtitle: 'N° 164',
                              disabled: false,
                              link: 'https://find.bloqs4.com/QmZE6B1nDbQvj7RQkvnM9QvajjvPo6nDgQxF4BZuu59pGD'
                          },
                          {
                              icon: 'ticket.svg',
                              title: 'Ticket de Pesaje',
                              subtitle: 'N° 7865-7873',
                              disabled: false,
                              link: 'https://find.bloqs4.com/QmYL6bjoZYAPscxBXhLY17gcY59TrhDMZrbLj4xMMLG3jh'
                          },
                          {
                              icon: 'ubicacion.svg',
                              title: 'Ubicación',
                              subtitle: '',
                              disabled: false,
                              link: 'https://goo.gl/maps/pgfMFKVvALXZY5vNA'
                          }
                      ]
                  },
                  {
                      title: '23.920 KG Mix',
                      files: [
                          {
                              icon: 'factura.svg',
                              title: 'Factura',
                              subtitle: 'N° 587',
                              disabled: false,
                              link: 'https://find.bloqs4.com/QmUPksqCdWjydn2UC6VRgbDYeyfbAFNZU6Tmjjqz8tHeRm'
                          },
                          {
                              icon: 'guia.svg',
                              title: 'Guía de Despacho',
                              subtitle: 'N° ',
                              disabled: true,
                              link: ''
                          },
                          {
                              icon: 'ticket.svg',
                              title: 'Ticket de Pesaje',
                              subtitle: 'N° ',
                              disabled: true,
                              link: ''
                          },
                          {
                              icon: 'ubicacion.svg',
                              title: 'Ubicación',
                              subtitle: '',
                              disabled: true,
                              link: ''
                          }
                      ]
                  },
                  {
                      title: '20.000 KG Mix',
                      files: [
                          {
                              icon: 'factura.svg',
                              title: 'Factura',
                              subtitle: 'N° 43.357',
                              disabled: false,
                              link: 'https://find.bloqs4.com/Qmebm6hQX6PTKwKc7DfD9XV3fB6qoGcotmcXggs1WLtpYM'
                          },
                          {
                              icon: 'guia.svg',
                              title: 'Guía de Despacho',
                              subtitle: 'N° ',
                              disabled: true,
                              link: ''
                          },
                          {
                              icon: 'ticket.svg',
                              title: 'Ticket de Pesaje',
                              subtitle: 'N° ',
                              disabled: true,
                              link: ''
                          },
                          {
                              icon: 'ubicacion.svg',
                              title: 'Ubicación',
                              subtitle: '',
                              disabled: false,
                              link: 'https://goo.gl/maps/Lh1JK4P2NN22Y86X6'
                          }
                      ]
                  }
              ]
          },
          Aysen: {
              title: '11.650 KG Aysen',
              files: [
                  {
                      icon: 'factura.svg',
                      title: 'Factura',
                      subtitle: 'N° 103',
                      disabled: false,
                      link: 'https://find.bloqs4.com/QmdBvW5i7pT4X1hrzFZGx8aBrXJMjgH9CK4MrPrRw5hdK9'
                  },
                  {
                      icon: 'guia.svg',
                      title: 'Guía de Despacho',
                      subtitle: 'N° 148',
                      disabled: false,
                      link: 'https://find.bloqs4.com/QmdapYpqsqK9sZsFJvxwA91Bhz4gaaxZm26UuRiZYqE4fu'
                  },
                  {
                      icon: 'ticket.svg',
                      title: 'Ticket de Pesaje',
                      subtitle: 'N° 14.841',
                      disabled: false,
                      link: 'https://find.bloqs4.com/QmPPML2NsmSPwaXQH723B8iAjvJ7dz3vbMvyMh8ZYazrsc'
                  },
                  {
                      icon: 'ubicacion.svg',
                      title: 'Ubicación',
                      subtitle: '',
                      disabled: false,
                      link: 'https://goo.gl/maps/yasY5CNcJfcrYJi48'
                  }
              ]
          },
          Curaco: {
              title: '11.950 KG Curaco de Vélez',
              files: [
                  {
                      icon: 'factura.svg',
                      title: 'Factura',
                      subtitle: 'N° 114',
                      disabled: false,
                      link: 'https://find.bloqs4.com/QmZyQbK3RR8HAvEzpas9FBscAPUjRoNpp5PWXfSobjDjfJ'
                  },
                  {
                      icon: 'guia.svg',
                      title: 'Guía de Despacho',
                      subtitle: 'N° 164',
                      disabled: false,
                      link: 'https://find.bloqs4.com/QmZE6B1nDbQvj7RQkvnM9QvajjvPo6nDgQxF4BZuu59pGD'
                  },
                  {
                      icon: 'ticket.svg',
                      title: 'Ticket de Pesaje',
                      subtitle: 'N° 7865-7873',
                      disabled: false,
                      link: 'https://find.bloqs4.com/QmYL6bjoZYAPscxBXhLY17gcY59TrhDMZrbLj4xMMLG3jh'
                  },
                  {
                      icon: 'ubicacion.svg',
                      title: 'Ubicación',
                      subtitle: '',
                      disabled: false,
                      link: 'https://goo.gl/maps/pgfMFKVvALXZY5vNA'
                  }
              ]
          },
          BCC: {
              title: '23.920 KG Mix',
              files: [
                  {
                      icon: 'factura.svg',
                      title: 'Factura',
                      subtitle: 'N° 587',
                      disabled: false,
                      link: 'https://find.bloqs4.com/QmUPksqCdWjydn2UC6VRgbDYeyfbAFNZU6Tmjjqz8tHeRm'
                  },
                  {
                      icon: 'guia.svg',
                      title: 'Guía de Despacho',
                      subtitle: 'N° ',
                      disabled: true,
                      link: ''
                  },
                  {
                      icon: 'ticket.svg',
                      title: 'Ticket de Pesaje',
                      subtitle: 'N° ',
                      disabled: true,
                      link: ''
                  },
                  {
                      icon: 'ubicacion.svg',
                      title: 'Ubicación',
                      subtitle: '',
                      disabled: true,
                      link: ''
                  }
              ]
          },
          Comberplast: {
              title: '20.000 KG Mix',
              files: [
                  {
                      icon: 'factura.svg',
                      title: 'Factura',
                      subtitle: 'N° 43.357',
                      disabled: false,
                      link: 'https://find.bloqs4.com/Qmebm6hQX6PTKwKc7DfD9XV3fB6qoGcotmcXggs1WLtpYM'
                  },
                  {
                      icon: 'guia.svg',
                      title: 'Guía de Despacho',
                      subtitle: 'N° ',
                      disabled: true,
                      link: ''
                  },
                  {
                      icon: 'ticket.svg',
                      title: 'Ticket de Pesaje',
                      subtitle: 'N° ',
                      disabled: true,
                      link: ''
                  },
                  {
                      icon: 'ubicacion.svg',
                      title: 'Ubicación',
                      subtitle: '',
                      disabled: false,
                      link: 'https://goo.gl/maps/Lh1JK4P2NN22Y86X6'
                  }
              ]
          }

      }
    }
};
</script>
