<template>
    <div class="Files">
        <v-expansion-panels
                v-model="panel"
                dark
                class="hidden-lg-and-up"
        >
            <v-expansion-panel style="background-color: transparent; box-shadow: none; -webkit-box-shadow: none">
                <v-expansion-panel-header class="text-no-wrap" hide-actions style="min-width: 250px">
                    <v-chip class="text-md-center font-weight-black" color="#9ac234" dark>{{ title }}</v-chip>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list dense dark color="rgb(0, 0, 0, 0)">
                        <template v-for="(item, index) in files">
                            <v-list-item
                                    :key="index"
                                    avatar
                                    @click="openLink(item.link)"
                                    :disabled="item.disabled"
                            >
                                <v-list-item-avatar tile size="36">
                                    <v-img contain :src="require('@/assets/'+item.icon)" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold body-1 title" style="font-size: 12px !important;">{{ item.title }}</v-list-item-title>
                                    <v-list-item-subtitle class="font-weight-bold subtitle" style="font-size: 11px !important;">{{ item.subtitle }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-col class="hidden-md-and-down">
            <v-row align="center" justify="center">
                <v-chip class="text-md-center font-weight-black pa-2 px-10" color="#9ac234" dark>{{ title }}</v-chip>
            </v-row>
            <v-row align="center" justify="center">
                <v-list dense dark color="rgb(0, 0, 0, 0)">
                    <template v-for="(item, index) in files">
                        <v-list-item
                                :key="index"
                                avatar
                                @click="openLink(item.link)"
                                :disabled="item.disabled"
                        >
                            <v-list-item-avatar tile size="36">
                                <v-img contain :src="require('@/assets/'+item.icon)" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold body-1 title" style="font-size: 12px !important;">{{ item.title }}</v-list-item-title>
                                <v-list-item-subtitle class="font-weight-bold subtitle" style="font-size: 11px !important;">{{ item.subtitle }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                    </template>
                </v-list>
            </v-row>
        </v-col>
    </div>
</template>

<script>
    import './_Files.scss'

    export default {
        name: 'Files',
        props: {
            title: String,
            files: Array,
            hiddenBody: { default: false, type: Boolean }
        },
        data () {
            return {
                panel: []
            }
        },
        methods: {
            openLink (link) {
                window.open(link, '_blank');
            }
        }
    };
</script>
